import React, {useState} from 'react';
import './Partners.css';

const Partners = () => {
    const [isDescriptionOpen, setDescriptionOpen] = useState(false);
    const [showX, setshowX] = useState(true);

    const toggleDescription = () => {
        setDescriptionOpen(!isDescriptionOpen);
    };

  return (
    <div>
        {/* <div className="title-description">
            <h1>Our Partners</h1>
            <div className={`description-toggle ${showX ? 'show-x' : ''}`} onMouseEnter={toggleDescription}>
              {isDescriptionOpen ? "" : 'X'}
            </div>
            <div className={`page-description ${isDescriptionOpen ? '' : 'closed'}`} onMouseLeave={toggleDescription}>
                <p>Graphomics is proud to stand alongside visionary partners in the biotech realm, forging a path of groundbreaking advancements in biology. We are committed to expanding access, raising awareness, and disseminating invaluable information. Our collective efforts drive innovation, fostering a future where the frontiers of biotechnology are continually pushed, ushering in new possibilities and insights.</p>
            </div>
        </div> */}
        <div className="partners-container">
            <div className="partner-container">
                <div className="partner-item">
                    <a href="https://www.justdrul.com/" target="_blank" rel="noopener noreferrer">
                        <img src="../assets/drul.svg" alt="drul logo" height="100px" className="partner-logo" />
                    </a>
                    <div className="partner-details">
                        <p>Dental care is the #1 medical service skipped due to cost. By the time people feel discomfort, irreversible damage may have already taken place. Drul's device collects saliva, isolates harmful bacteria, and quantifies bacterial concentration to identify the early onset of oral disease. Today, they can detect cavities and gum disease - the most prevalent oral diseases globally.</p>
                    </div>
                </div>
                {/* <div className="partner-item">
                    <a href="https://www.phenobiome.com/index.html" target="_blank" rel="noopener noreferrer">
                        <img src="../assets/phenobiome.jpeg" alt="phenobiome logo" height="100px" className="partner-logo" />
                    </a>
                    <div className="partner-details">
                        <p> At the forefront of microbiome science, PhenoBiome's platform pioneers the conversion of taxonomic signatures and gene lists into microbiome-wide quantitative functional capabilities. They employ a groundbreaking methodology to deduce metabolic phenotypes, offering a nuanced understanding of microbial interactions with the host, each other, and the diet. Through quantitative assessments, they unravel functional disparities in microbiome samples, empowering advancements in interpreting microbiome data. Their expertise extends to identifying probiotic candidates and developing personalized nutraceutics for microbiome modulation.</p>
                    </div>
                </div> */}
            </div>
            <div className="become-partner">
                <p className="become-message">If you would like to become a partner, please connect with us via email. Serious inquiries only.</p>
            </div>
        </div>
    </div>
  );
};

export default Partners;
