import React, { useEffect, useRef } from 'react';
import './Solutions.css';

export default function Solutions() {
    const gridItemRefs = useRef([]);
    const gridTextRefs = useRef([]);

    useEffect(() => {
      const options = {
        root: null,
        rootMargin: '0px',
        threshold: 0.5,
      };
  
      const callback = (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            entry.target.classList.add('animate');
          } else {
            entry.target.classList.remove('animate');
          }
        });
      };
  
      const observer = new IntersectionObserver(callback, options);
  
      gridItemRefs.current.forEach((ref) => {
        observer.observe(ref);
      });

      gridTextRefs.current.forEach((ref) => {
        observer.observe(ref);
      });
  
      return () => {
        observer.disconnect();
      };
    }, []);
  return (
    <div>
        <div className="page_info">
            <p className="page_header" >Accelerate Diagnostics and Drug Discovery through AI</p>
            <p className="page_body" >Our Services</p>
        </div>
        <div class="grid-container">
            <div ref={(el) => (gridItemRefs.current[0] = el)} class="grid-item image grid-item-image">
                <div className="grid-item-content">
                    <img src="../assets/cloud.png" className="grid_icon"></img>
                    <div class="horizontal-line"></div>
                </div>
            </div>
            <div ref={(el) => (gridTextRefs.current[0] = el)} class="grid-item-text">
                <div className="grid-text-header">
                    AI-Powered Diagnostics and Drug Discovery
                </div>
                <div className="grid-text-body">
                    At Graphomics, we offer state-of-the-art AI-powered solutions to accelerate diagnostics and drug discovery for Life Sciences companies. Our platform leverages the latest advancements in machine learning and data analytics to help our customers make informed decisions and achieve their goals faster.
                </div>
            </div>
            <div ref={(el) => (gridItemRefs.current[1] = el)} class="grid-item image grid-item-image">
                <div className="grid-item-content-2">
                    <img src="../assets/analyse.png" className="grid_icon"></img>
                    <div class="horizontal-line"></div>
                </div>
            </div>
            <div ref={(el) => (gridTextRefs.current[1] = el)} class="grid-item-text">
                <div className="grid-text-header">
                    Data Science Consulting
                </div>
                <div className="grid-text-body">
                    Our team of experienced data scientists provides consulting services to help our customers extract meaningful insights from their data. We work closely with our clients to understand their unique needs and develop tailored solutions that drive value and improve performance.
                </div>
            </div>
            <div ref={(el) => (gridItemRefs.current[2] = el)} class="grid-item image grid-item-image">
                <div className="grid-item-content">
                    <img src="../assets/customization.png" className="grid_icon"></img>
                    <div class="horizontal-line"></div>
                </div>
            </div>
            <div ref={(el) => (gridTextRefs.current[2] = el)} class="grid-item-text">
                <div className="grid-text-header">
                    Custom Software Development
                </div>
                <div className="grid-text-body">
                    In addition to our AI and data science offerings, we also provide custom software development services to help our clients build scalable and reliable applications that meet their specific requirements. Our team of developers is skilled in a variety of programming languages and frameworks, and can deliver solutions on time and within budget.
                </div>
            </div>
            <div ref={(el) => (gridItemRefs.current[3] = el)} class="grid-item image grid-item-image">
                <div className="grid-item-content-2">
                    <img src="../assets/together.png" className="grid_icon"></img>
                    <div class="horizontal-line"></div>
                </div>
            </div>
            <div ref={(el) => (gridTextRefs.current[3] = el)} class="grid-item-text">
                <div className="grid-text-header">
                    Expert Support and Maintenance
                </div>
                <div className="grid-text-body">
                    At Graphomics, we are committed to providing our customers with the best possible experience. That's why we offer expert support and maintenance services to ensure that our solutions are always up-to-date and running smoothly. Our team of dedicated professionals is available 24/7 to answer questions and resolve issues quickly and efficiently.
                </div>
            </div>
        </div>
        <div class="grid-container-2">
            <div class="left-column-2">Partnering for Success</div>
            <div class="right-column-2">
                At Graphomics, we believe that success is achieved through collaboration. That's why we work closely with our customers to understand their goals and provide solutions that drive real business value. We integrate seamlessly with our clients' ecosystems to ensure a smooth and efficient integration process, and provide ongoing support to ensure continued success.
            </div>
        </div>
    </div>
  )
}
