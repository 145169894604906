import React from 'react';
import './Product.css';

const Product = () => {
  return (
    <div>
      <h1>What is Graphomics?</h1>
    </div>
  );
};

export default Product;
