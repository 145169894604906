import React, { useEffect, useRef } from 'react';
import './Vision.css';

export default function Vision() {
    const quadrantRefTopRight = useRef(null);
    const quadrantRefBottomLeft = useRef(null);
    const quadrantRefTopLeft = useRef(null);
    const quadrantRefBottomRight = useRef(null);

    useEffect(() => {
        const quadrant = document.getElementById('top-left');
        const quadrantBottomRight = document.getElementById('bottom-right');

        const handleScroll = () => {
          const scrollPosition = window.scrollY;
          quadrant.style.backgroundPositionX = `${-scrollPosition * 0.3}px`;
          quadrantBottomRight.style.backgroundPositionX = `${-scrollPosition * 0.3}px`;
          const topRightPosition = quadrantRefTopRight.current.offsetTop;
          const bottomLeftPosition = quadrantRefBottomLeft.current.offsetTop;
          
          if (scrollPosition > topRightPosition - window.innerHeight) {
            quadrantRefTopRight.current.classList.add('fly-in-left-to-right');
          }
          
          if (scrollPosition > bottomLeftPosition - window.innerHeight) {
            quadrantRefBottomLeft.current.classList.add('fly-in-right-to-left');
        }

        };
        window.addEventListener('scroll', handleScroll);
        return () => {
          window.removeEventListener('scroll', handleScroll);
        };
      }, []);

    return (
        <div>
            <div>
                <div className="page_info">
                    <p className="page_header" >Our Commitment to AI-Driven Drug Discovery</p>
                    <p className="page_body" >At Graphomics, we are dedicated to accelerating drug discovery and diagnostics through the power of artificial intelligence. Our mission is to provide life sciences companies with cutting-edge technology solutions tailored to their specific needs. By leveraging the latest advances in AI and machine learning, we are able to help our clients optimize their research and development processes, saving them time and money. We are committed to staying at the forefront of this rapidly evolving field, and to continuously pushing the boundaries of what is possible.</p>
                </div>
                <div className="quadrant-container">
                    <div className="vision-grid-top-row">
                        <div className="quadrant" id="top-left" style={{ backgroundImage: `url('https://cdn4.vectorstock.com/i/1000x1000/23/98/digital-flow-of-particles-abstract-wave-vector-17192398.jpg')` }}>
                            <div className="quadrant_header">Our Journey</div>
                        </div>
                        <div className="quadrant" id="top-right" ref={quadrantRefTopRight}>
                            <div className="quadrant_body">Graphomics was founded by a team of computer science experts with a passion for using technology to solve complex problems in the life sciences industry. Our journey began with a simple idea: to create a platform that would enable researchers to harness the power of AI in their work. Since then, we have grown into a leading provider of AI-driven solutions for drug discovery and diagnostics. Our team is made up of scientists, engineers, and business professionals who share a common goal: to revolutionize the way that life sciences research is conducted.</div>
                        </div>
                    </div>
                    <div className="vision-grid-bottom-row">
                        <div className="quadrant" id="bottom-left" ref={quadrantRefBottomLeft}>
                            <div className="quadrant_body">At Graphomics, we are proud to have a team of experienced leaders who are dedicated to our mission of using AI to transform the life sciences industry. Our team includes scientists, engineers, and business professionals who bring a wealth of expertise to every project. Together, we are committed to delivering innovative solutions that help our clients achieve their goals. Whether you are a small biotech startup or a large pharmaceutical company, you can count on us to provide the support and guidance you need to succeed.</div>
                        </div>
                        <div className="quadrant" id="bottom-right" style={{ backgroundImage: `url('https://cdn4.vectorstock.com/i/1000x1000/23/98/digital-flow-of-particles-abstract-wave-vector-17192398.jpg')` }}>
                            <div className="quadrant_header">Experienced<br />Leadership</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}