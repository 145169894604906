import React, { useState, useEffect } from 'react';
import { NavLink, useLocation } from 'react-router-dom';
import "./Nav.css";

const Nav = () => {
    const location = useLocation();
    const [activeLink, setActiveLink] = useState(''); // State to track the active link
    const [isHovered, setIsHovered] = useState(false);
    const [isAtTop, setIsAtTop] = useState(true);

    useEffect(() => {
        const pathname = location.pathname;
        setActiveLink(pathname === '/' ? 'home' : pathname.substring(1));
    }, [location.pathname]);

    useEffect(() => {
        const handleScroll = () => {
            setIsAtTop(window.scrollY === 0);
        };
    
        window.addEventListener('scroll', handleScroll);
    
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);
  
    const handleLinkClick = (link) => {
        setActiveLink(link);
        window.scrollTo(0, 0);
    };

    const handleLogoClick = () => {
        handleLinkClick("home");
    };
  
    return (
    <div className={`links ${isAtTop || isHovered ? 'transparent' : ''}`} onMouseEnter={() => setIsHovered(true)} onMouseLeave={() => setIsHovered(false)}>
        <div className="logo-container">
            <NavLink to="/" onClick={handleLogoClick}>
                <div className="nav-item">
                    <img src="../assets/graphomics_logo.png" alt="Logo" height="36px" />
                    <span>Graphomics</span>
                </div>
            </NavLink>
        </div>
        <div className="page-link-container">
            <NavLink to="/" onClick={() => handleLinkClick('home')}>
                <div className={`nav-item ${activeLink === 'home' ? 'active' : ''}`}>
                    <span>Home</span>
                </div>
            </NavLink>
            <NavLink to="/solutions" onClick={() => handleLinkClick('solutions')}>
                <div className={`nav-item ${activeLink === 'solutions' ? 'active' : ''}`}>
                    <span>Solutions</span>
                </div>
            </NavLink>
            <NavLink to="/vision" onClick={() => handleLinkClick('vision')}>
                <div className={`nav-item ${activeLink === 'vision' ? 'active' : ''}`}>
                    <span>Vision</span>
                </div>
            </NavLink>
            {/* <NavLink to="/team" onClick={() => handleLinkClick('team')}>
                <div className={`nav-item ${activeLink === 'team' ? 'active' : ''}`}>
                    <span>Team</span>
                </div>
            </NavLink> */}
            <NavLink to="/partners" onClick={() => handleLinkClick('partners')}>
                <div className={`nav-item ${activeLink === 'partners' ? 'active' : ''}`}>
                    <span>Partners</span>
                </div>
            </NavLink>
        </div>
    </div>
  )
}

export default Nav;
