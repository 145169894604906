import React, { useEffect, useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import "./Home.css";

const Home = () => {
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);
  const [hoveredItem, setHoveredItem] = useState(2);

  const handleMouseEnter = (item) => {
    setHoveredItem(item);
  };

  const handleResize = () => {
    setScreenWidth(window.innerWidth);
  };

  useEffect(() => {
    window.addEventListener('resize', handleResize); // Add event listener for window resize
    return () => {
      window.removeEventListener('resize', handleResize); // Remove event listener on component unmount
    };
  }, []);

  return (
    <div>
      <div className='page-info'>
        <div className='page-header'>
          Accelerate Your Life Sciences Research with Graphomics
        </div>
        <div className='page-sub-header'>
          Use AI to Accelerate Diagnostics and Drug Discovery
        </div>
        <div className='button-container'>
          <Link to="/solutions" className='learn-more'>
            Learn More
          </Link>
        </div>
      </div>
      <div className="home-grid-container">
        <div className="home-grid-left">
          <div className='home-grid-left-item-header'>
            Revolutionize Life Sciences Research
          </div>
          <div className='home-grid-left-item-body'>
            At Graphomics, we believe that AI can accelerate diagnostics and drug discovery in Life Sciences. We are a technology platform company geared towards helping Life Sciences companies revolutionize their research. Click here to learn more about our mission and values.
          </div>
        </div>
        <div className="home-grid-right">
          <div className='home-grid-right-item' id='first'>
            <div>
              <img src="../assets/cloud-green.png" className='home-grid-right-item-img'></img>
            </div>
            <div className='home-grid-right-item-header'>
              Cloud-based Data Analytics
            </div>
            <div className='home-grid-right-item-body'>
              We provide a cloud-based data analytics platform that allows you to analyze your data in real-time. Our platform is designed to be user-friendly and scalable, so you can focus on your research without worrying about the technology.
            </div>
            <div className="home-horizontal-line"></div>
          </div>
          <div className='home-grid-right-item' id='second'>
            <div>
              <img src="../assets/analyse-green.png" className='home-grid-right-item-img'></img>
            </div>
            <div className='home-grid-right-item-header-data'>
              Data Science Expertise
            </div>
            <div className='home-grid-right-item-body'>
              Our team consists of data science experts who are passionate about using AI to accelerate Life Sciences research. We are committed to providing the best technology solutions and support to help you achieve your research goals.
            </div>
            <div className="home-horizontal-line"></div>
          </div>
          <div className='home-grid-right-item' id='third'>
            <div>
              <img src="../assets/customization-green.png" className='home-grid-right-item-img'></img>
            </div>
            <div className='home-grid-right-item-header'>
              Versatile Technology Solutions
            </div>
            <div className='home-grid-right-item-body'>
              Our platform is versatile and can be customized to meet your specific needs. Whether you are a small startup or a large enterprise, we have the technology solutions to help you accelerate your research.
            </div>
            <div className="home-horizontal-line"></div>
          </div>
          <div className='home-grid-right-item' id='fourth'>
            <div>
              <img src="../assets/together-green.png" className='home-grid-right-item-img'></img>
            </div>
            <div className='home-grid-right-item-header'>  
              Customer-focused Service
            </div>
            <div className='home-grid-right-item-body'>
              At Graphomics, we are committed to providing the best customer service possible. Our team is available 24/7 to answer your questions and provide support whenever you need it.
            </div>
            <div className="home-horizontal-line"></div>
          </div>
        </div>
      </div>
      <div className="home-grid-2-container">
        <div className="home-grid-2-item" style={{ color: 'white' }}>Founding Research</div>
        <div
          className="home-grid-2-item"
          onMouseEnter={() => handleMouseEnter(2)}
          style={{ color: hoveredItem === 2 ? 'white' : 'slategray' }}
        >
          Neural Graph Reasoning
        </div>
        <div
          className="home-grid-2-item"
          onMouseEnter={() => handleMouseEnter(3)}
          style={{ color: hoveredItem === 3 ? 'white' : 'slategray' }}
        >
          Hypergraph
        </div>
        <div
          className="home-grid-2-item"
          onMouseEnter={() => handleMouseEnter(4)}
          style={{ color: hoveredItem === 4 ? 'white' : 'slategray' }}
        >
          Large Scale Pathway Analysis
        </div>
        <div
          className="home-grid-2-item"
          onMouseEnter={() => handleMouseEnter(5)}
          style={{ color: hoveredItem === 5 ? 'white' : 'slategray' }}
        >
          KGNMDA
        </div>
      </div>
      <div className="home-grid-3-container">
        <div className="home-grid-3-item">
          {hoveredItem === 2 && (
              <div className="paper-preview-container">
                <img src="../assets/graph.jpg" className="paper-preview" alt="Research Paper Preview" />
                <button className="view-pdf-btn" onClick={() => window.open("../assets/graph.pdf", "_blank")}>
                  View PDF
                </button>
              </div>
            )}
            {hoveredItem === 3 && (
            <div className="paper-preview-container">
              <img src="../assets/hypergraph.jpg" className="paper-preview" alt="Research Paper Preview" />
              <button className="view-pdf-btn" onClick={() => window.open("../assets/hypergraph.pdf", "_blank")}>
                View PDF
              </button>
            </div>
          )}
          {hoveredItem === 4 && (
            <div className="paper-preview-container">
              <img src="../assets/hidp.jpg" className="paper-preview" alt="Research Paper Preview" />
              <button className="view-pdf-btn" onClick={() => window.open("../assets/hidp.pdf", "_blank")}>
                View PDF
              </button>
            </div>
          )}
          {hoveredItem === 5 && (
            <div className="paper-preview-container">
              <img src="../assets/kgnmda.jpg" className="paper-preview" alt="Research Paper Preview" />
              <button className="view-pdf-btn" onClick={() => window.open("../assets/kgnmda.pdf", "_blank")}>
                View PDF
              </button>
            </div>
          )}
        </div>
        <div className="home-grid-3-item">
          {hoveredItem === 2 && <div>Neural Graph Reasoning is a method that addresses Complex Logical Query Answering (CLQA) in graph machine learning. It extends beyond simple link prediction to multi-hop logical reasoning over vast, potentially incomplete graphs. The approach involves a comprehensive survey of CLQA, covering various aspects such as graph types, modeling techniques, supported queries, datasets, and applications. Additionally, Ren et al. Neural Graph Databases (NGDBs) as an enhancement, offering a flexible and unified modeling approach, particularly beneficial for incomplete graphs.</div>}
          {hoveredItem === 3 && <div>Feng et al. use hypergraphs to analyze biological networks, aiming to uncover key genes from genomic expression data. Hypergraphs, which capture multi-way relationships among genes and proteins, offer a more nuanced representation compared to traditional graphs. By applying hypergraph centrality measures to a dataset of host responses to viral infections, they identify crucial genes involved in viral response. The findings demonstrate the effectiveness of hypergraphs in elucidating complex biological systems and identifying essential genes shared across diverse pathogenic viruses.</div>}
          {hoveredItem === 4 && <div>Discovering disease pathways, crucial for diagnosis and treatment, relies on protein-protein interaction (PPI) networks. However, most pathways exhibit fragmented structures in PPI networks, hindering method success. Evaluating 519 disease pathways, Agrawal et al. find that 90% comprise disconnected protein regions. They propose that traditional network connectivity metrics may not suffice and advocate for exploring higher-order network structures, like small subgraphs, to enhance pathway discovery approaches.</div>}
          {hoveredItem === 5 && <div>Various microbes in the human body play a role in complex diseases, offering insights into drug development. Existing computational methods for predicting microbe-disease associations often lack comprehensive biological information. To address this gap, Jiang et al. constructed a knowledge graph integrating microorganism and disease data from multiple databases. By incorporating Gaussian kernel similarity features, they generated final representations and devised a scoring function to predict microbe-disease associations. Their approach, validated on the Human Microbe-Disease Association Database (HMDAD) dataset, outperformed baseline methods.</div>}
        </div>
      </div>
    </div>
  );
}

export default Home;

