import React, { useState } from 'react';
import './Footer.css';
import CopyToClipboardNotification from './CopyToClipboardNotification';

const Footer = () => {
  const email = 'graphomics@gmail.com';
  const [isCopied, setIsCopied] = useState(false);

  const handleCopyToClipboard = () => {
    const tempInput = document.createElement('input');
    tempInput.value = email;
    document.body.appendChild(tempInput);
    tempInput.select();
    document.execCommand('copy');
    document.body.removeChild(tempInput);

    setIsCopied(true);
    setTimeout(() => setIsCopied(false), 1000);
  };

  return (
    <div className="footer-container">
      <div className="graph-container">
        <img src="../assets/graphomics_logo.png" alt="Logo" height="64px" className="graph_logo" />
        <span>Graphomics</span>
      </div>
      <div className="connect-container">
        <p>Connect with Us</p>
        <div className="email-container" onClick={handleCopyToClipboard}>
          <p className="email-text">{email}</p>
          {isCopied ? (
              <img src="../assets/checkbox.png" height="24px" alt="Checkbox" />
            ) : (
              <img src="../assets/copy.png" height="24px" alt="Email Icon" />
            )}
        </div>
      </div>
    </div>
  );
};

export default Footer;
