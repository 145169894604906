import logo from './logo.svg';
import './App.css';
import { BrowserRouter as Router, Routes, Route, Switch } from 'react-router-dom';
import Home from "./screens/Home.jsx";
import Nav from "./components/Nav.jsx";
import Partners from "./screens/Partners.jsx";
import Team from "./screens/Team.jsx";
import Research from "./screens/Research.jsx";
import Vision from "./screens/Vision.jsx";
import Solutions from "./screens/Solutions.jsx";
import Product from "./screens/Product.jsx";
import Footer from "./components/Footer.jsx";
import { AppProvider } from './contexts/AppContext';


function App() {
  return (
    <div className="App">
      <AppProvider>
        <Nav />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/partners" element={<Partners />} />
          {/* <Route path="/Team" element={<Team />} /> */}
          {/* <Route path="/Research" element={<Research />} /> */}
          <Route path="/Vision" element={<Vision />} />
          <Route path="/Solutions" element={<Solutions />} />
          {/* <Route path="/Product" element={<Product />} /> */}
        </Routes>
        <Footer />
      </AppProvider>
    </div>
  );
}

export default App;
